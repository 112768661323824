@import '~@andes/technical-error/index';
@import '~@andes/badge/index';
@import '~@andes/button/base';
@import '~@andes/button/lib/styles/modifier/transparent';
@import '~@andes/snackbar/index';
@import '~@andes/thumbnail/base';
@import '~@andes/thumbnail/lib/styles/modifier/circle';
@import '~@andes/typography/index';

body {
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
}

// Centers the whole screen error to avoid issues
.shop-error-page {
  height: 100%;
  position: absolute;
  width: 100%;
}
